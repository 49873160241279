import {rootStore} from "@/store/rootStore";
import axios from "axios";

export interface IErrorMessage {
    message?: string;
    details?: string[];
}

export const instance = axios.create({
    baseURL: process.env.NODE_ENV === 'production' ? process.env.REACT_APP_HOST_BACKEND_PROD : process.env.REACT_APP_HOST_BACKEND_DEV,
    timeout: 30000,
    withCredentials: true,
})

let refreshSubscribes: any = []
let isRefreshing = false

instance.interceptors.request.use(options => {
    if (rootStore.token) {
        options.headers = {
            Authorization: `Bearer ${rootStore.token}`,
            "Content-Type": "application/json",
        }
    }
    if (options.url !== '/auth/sign-in') {
        options.withCredentials = true;
    }
    return options
})
instance.interceptors.response.use(options => {
    return options
}, async error => {
    const originalRequest = error.config;
    if (error?.response.status === 401 && !originalRequest._retry) {
        if (isRefreshing) {
            return new Promise((resolve) => {
                refreshSubscribes.push((token: string) => {
                    originalRequest.headers.Authorization = `Bearer ${token}`;
                    resolve(instance(originalRequest));
                })
            })
        }

        if (!localStorage.getItem('saveMe')) {
            localStorage.clear()
            rootStore.setToken(null)
            rootStore.setRefreshToken(null)
            return Promise.reject()
        }

        originalRequest._retry = true;
        isRefreshing = true;

        return new Promise((resolve, reject) => {
            instance.post(`${process.env.REACT_APP_HOST_BACKEND}/auth/refresh-token`, {
                refreshToken: localStorage.getItem("refreshToken")
            }).then(response => {
                onTokenRefreshed(response.data.access_token)
                localStorage.setItem("token", response.data.access_token)
                localStorage.setItem("refreshToken", response.data.refresh_token)
                rootStore.setToken(response.data.access_token)
                rootStore.setRefreshToken(response.data.refresh_token)
                resolve(response.data.access_token)
            }).catch(e => {
                if (e.response.status === 500) {
                    localStorage.clear()
                    rootStore.setToken(null)
                    reject(e)
                }
            })
        }).then((token: any) => {
            originalRequest.headers.Authorization = `Bearer ${token}`;
            return instance(originalRequest);
        })
    }


    return Promise.reject(error)
})
const onTokenRefreshed = (token: string) => {
    instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    refreshSubscribes.map((callback: any) => callback(token));
    refreshSubscribes = [];
    isRefreshing = false;
};

